import * as React from "react";
import { colInsetCss, colOneCss, colTwoCss } from "./index.css";
import { Row } from "../Grid/Row";
import { Heading, Strapline } from "../../components/Heading";
import { Spacer } from "../../components/Spacer";
import { BoxParagraph } from "../../components/BoxParagraph";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";

export const ContentBlock: React.FC<{
  title: string;
  description: string;
  strapline?: string;
  image: IGatsbyImageData;
  imageDescription: string;
  imageLoading?: "lazy" | "eager";
  layout: "default" | "swap";
}> = function ContentBlock({
  title,
  description,
  strapline,
  image,
  imageDescription = "",
  imageLoading,
  layout = "default",
}) {
  return (
    <Row>
      <div className={colOneCss[layout]}>
        <div className={colInsetCss[layout === "default" ? "right" : "left"]}>
          <Strapline>{strapline}</Strapline>
          <Spacer size="extraLarge" />
          <Heading as="h2">{title}</Heading>
          <Spacer size="extraLarge" />
          <Spacer size="extraLarge" />
          <BoxParagraph
            html={description?.replace(/\n/g, "<br />")}
          ></BoxParagraph>
        </div>
      </div>
      <div className={colTwoCss[layout]}>
        <GatsbyImage
          image={image}
          loading={imageLoading}
          alt={imageDescription}
        />
      </div>
    </Row>
  );
};
