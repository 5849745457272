import * as React from "react";
import { graphql } from "gatsby";
import { useTitle, useMeta } from "hoofd";
import {
  MastHead,
  Headline,
  BackgroundImage,
} from "../modules/MastHead/index.ts";
import { ContentBlock } from "../modules/ContentBlock/index.tsx";
import { Container } from "../components/Container.tsx";
import DefaultLayout from "../layout/default";
import { sectionCss } from "../atomics.css";
import { Spacer } from "../components/Spacer";
import { Strapline } from "../components/Heading";

// markup
const IndexPage = ({ data: { contentfulPage } }) => {
  useTitle(contentfulPage.pageTitle || contentfulPage.title);
  useMeta({ name: "description", content: contentfulPage.description });

  return (
    <DefaultLayout>
      <MastHead>
        <Strapline theme="light">{contentfulPage.strapline}</Strapline>
        <Spacer size="extraLarge" />
        <Headline title={contentfulPage.title} />
        <BackgroundImage
          image={
            contentfulPage.titleBackground?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          alt=""
          role="presentation"
        />
      </MastHead>

      {contentfulPage.contentBlocks.map((node, index) => {
        return (
          <section
            style={{ scrollMarginTop: "20px" }}
            className={sectionCss}
            id={node.title.toLowerCase().replace(/\s/g, "-")}
            key={node.title.toLowerCase().replace(/\s/g, "-")}
          >
            <Container>
              <ContentBlock
                layout={index % 2 === 0 ? "default" : "swap"}
                title={node.title}
                strapline={node.strapline}
                description={node.description}
                imageLoading={index === 0 ? "eager" : "lazy"}
                imageDescription={node.image.title}
                image={node.image?.localFile.childImageSharp.gatsbyImageData}
              />
            </Container>
          </section>
        );
      })}
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "services" }) {
      title
      strapline
      titleBackground {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, height: 242)
          }
        }
      }
      pageTitle
      description
      contentBlocks {
        ... on ContentBlock {
          __typename
          id
          title
          description
        }
        ... on ContentfulServiceBlock {
          strapline
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 550
                  placeholder: TRACED_SVG
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
